import React, {useEffect, useState} from 'react'

import { Col, List, Row, Space, Typography } from 'antd'
import { QRCodeSVG }                         from 'qrcode.react'
import {QrCodeSmallIcon}                     from '../icons';
import { useIntl } from 'react-intl';
import ErrorPage                             from '../ErrorPage'

interface DpskActionVariables {
  QR_DISPLAY_REQUIRED: string;
  IDENTITY_ID: string;
  DPSK_PASSPHRASE: string;
  IDENTITY_GROUP_ID: string;
  DPSK_ID: string;
  NETWORKS: string;
  SMS_REQUIRED: string;
  EMAIL_REQUIRED: string;
}

function escapeSpecialCharacters(str: string): string {
  return str.replace(/[$&+,:;=?@#|'<>.^*()%!-]/g, '\\$&')
}

export function DpskAction (props: { data: DpskActionVariables }) {
  const { data } = props
  const { $t } = useIntl()
  const [selectedSsid, setSelectedSsid] = useState<string>('')
  const networkList = data.NETWORKS.split(',')

  const { Title, Text, Link } = Typography

  useEffect(() => {
    if (networkList.length === 1) {
      setSelectedSsid(networkList[0])
    }
  }, [])

  if (networkList.length === 0 || networkList[0] === '') {
    return (<ErrorPage message={$t({ defaultMessage: 'No networks available.' })}/>)
  }

  return (
    (networkList.length > 1 && selectedSsid === '') ?
      <Space direction='vertical' size='large'>
        <Title level={2}>
        {$t({defaultMessage: 'Select a network to connect:'})}
        </Title>
        <Space>
          <Text strong>
          {$t({defaultMessage: 'Personal password:'})}
          </Text>
          <Text>{ data.DPSK_PASSPHRASE }</Text>
        </Space>
        <List
          bordered
          dataSource={networkList}
          renderItem={(ssid) => (
            <List.Item>
              <Row justify='space-between' style={{ width: '100%' }}>
                <Col>
                  <Text>{ ssid }</Text>
                </Col>
                <Col>
                  <Space align='baseline'>
                    {/* eslint-disable-next-line max-len */}
                    <Link onClick={() => setSelectedSsid(ssid)}>Connect</Link>
                    <QrCodeSmallIcon/>
                  </Space>
                </Col>
              </Row>
            </List.Item>
          )}
        />
      </Space> :
      <Space direction='vertical' size='large'>
        <Title level={2}>
          {$t({defaultMessage: 'Connect to the network:'})}
        </Title>
        <Space>
          <Text strong>
            {$t({defaultMessage: 'Wi-Fi Network name:'})}
          </Text>
          <Text>{ selectedSsid }</Text>
        </Space>
        <Space>
          <Text strong>
            {$t({defaultMessage: 'Personal password:'})}
          </Text>
          <Text>{ data.DPSK_PASSPHRASE }</Text>
        </Space>
        <Space direction='vertical' align='center'>
          <Text>
            {$t({defaultMessage: 'Scan or click this QR code to connect to the network:'})}
          </Text>
          <QRCodeSVG
            data-testid='qr-code'
            value={`WIFI:T:WPA;S:${selectedSsid};P:${escapeSpecialCharacters(data.DPSK_PASSPHRASE)};H:false;`}
            size={180}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'L'}
            includeMargin={false}
            onClick={() => {
              if (networkList.length > 1) {
                setSelectedSsid('');
              }
            }}
          />
        </Space>
      </Space>
  )
}
