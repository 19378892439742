import React, { useEffect, useState }   from 'react'
import { List, Row, Space, Typography } from 'antd'
import { useIntl }                      from 'react-intl'
import ErrorPage                        from '../ErrorPage'

interface MacRegOnboardedVariables {
  MAC_ADDRESS: string;
  IDENTITY_ID: string;
  IDENTITY_GROUP_ID: string;
  NETWORKS: string;
}
export function MacRegOnboarded(props: { data: MacRegOnboardedVariables }) {
  const { $t } = useIntl()
  const { Text, Link } = Typography
  const { data } = props
  const networkList = data.NETWORKS.split(', ')
  const [selectedSsid, setSelectedSsid] = useState<string>('')
  const macAddress = data.MAC_ADDRESS;
  useEffect(() => {
    if (networkList.length === 1) {
      setSelectedSsid(networkList[0])
    }
  }, [])

  if (networkList.length === 0 || networkList[0] === '') {
    return (<ErrorPage message={$t({ defaultMessage: 'No networks available.' })}/>)
  }

  return (
    <Space direction='vertical' size='large'>
      <Text>
        {$t({ defaultMessage: 'The device with MAC address ' })}
        <Text strong data-testid='macAdd'>{macAddress} </Text>
        {$t({ defaultMessage: ' is now allowed to connect to the following network:' })}
      </Text>

      <List bordered
        dataSource={selectedSsid ? [selectedSsid] : networkList}
        renderItem={
          (ssid, index) => (
            (ssid && ssid.trim().length > 0) && <List.Item>
              <Row justify='space-between' style={{ width: '100%' }}>
                <Space align='baseline'>
                  {(networkList.length > 1) ?
                    <Link data-testid={`lnw-${index}`}
                      onClick={() => setSelectedSsid(selectedSsid ? '' : ssid)}
                      strong={true} >
                      <Text strong={true}><b>{ssid}</b><br/></Text>
                    </Link>
                    : <Text strong={true}><b>{selectedSsid}</b><br/></Text>
                  }
                </Space>
              </Row>
            </List.Item>
          )} />
    </Space>
  )
}
