import {baseApi}                      from './base'
import {UIColorSchema, UIStyleSchema} from '../types/UIConfiguration';


export interface ActionInput {
  actionType: string;
}

export interface FileDownloadResponse {
  url: string;
}

export interface AupInput extends ActionInput {
  actionType: 'AUP';
  acceptAup: boolean;
}

export interface MacRegInout extends ActionInput {
  actionType: 'MAC_REG';
  macAddress: string;
}

export interface DataPromptInput extends ActionInput {
  actionType: 'DATA_PROMPT';
  userName: string;
  email: string;
  phoneNumber?: string;
  firstName?: string;
  lastName?: string;
  inputField1?: string;
  inputField2?: string;
  inputField3?: string;
  inputField4?: string;
  inputField5?: string;
  inputField6?: string;
}

export interface DisplayMessageInput extends ActionInput {
  actionType: 'DISPLAY_MESSAGE';
}

interface ErrorResource {
  status: string;
  timestamp: string;
  message: string;
  detailedMessage?: string;
  validationErrors?: string[];
  requestId?: string;
}

interface EntityModelAcceptedResponse {
  requestId?: string;
  _links?: Links;
}

interface Links {
  [key: string]: Link;
}

interface Link {
  href: string;
  hreflang?: string;
  title?: string;
  type?: string;
  deprecation?: string;
  profile?: string;
  name?: string;
  templated?: boolean;
}

interface LoginPayload {
  accessToken: string;
  enrollmentId: string | null;
}

interface Enrollment {
  id: string;
  description?: string;
  status: 'CREATED' | 'STARTED' | 'FINISHED' | 'ABANDONED' | 'ACTIVE' | 'INACTIVE' | 'REVOKED';
}

interface EntityModelEnrollmentToken {
  workflowId: string;
  token: string;
  enrollment: Enrollment;
  _links?: Links;
}

interface EnrollmentStep {
  id: EnrollmentStepId;
  execution: StepActionExecution;
  revocation?: Revocation;
  input?: ActionInput;
  context: WorkflowContext;
  status: 'SUCCESS' | 'FAILED'
  variables?: any
}

interface EnrollmentStepId {
  enrollmentId: string;
  stepId: string;
}

interface Revocation {
  revokedDate: string;
  revokedReason?: string;
  revokedBy?: string;
}

interface StepActionExecution {
  actionId: string;
  status: 'IN_PROGRESS' | 'SUCCESS' | 'FAILED';
  failureReason?: string;
  variables?: { [key: string]: string };
}

interface WorkflowContext {
  workflowId: string;
  tenantId?: string;
}

export interface WorkflowConfig {
  id?: string;
  name?: string;
  color: UIColorSchema;
  styles: UIStyleSchema;
  startStepId?: string;
  firstActionStepId: string;
}

export interface EnrollmentStepConfig {
  step?: EnrollmentStep;
  config: StepConfig;
}

export interface FileResponse {
  url?: string;
}

export interface StepConfig {
  stepId: string;
  stepType: string;
  actionType: string;
  actionId?: string;
  actionConfiguration?: string;
  options?: { [key: string]: StepConfig };
  providedEnrollmentVariables?: string[];
  requiredEnrollmentVariables?: string[];
}

export const deviceEnrollmentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getWorkflowConfig: builder.query<WorkflowConfig, any>({
      query: () => ({
        url: ''
      })
    }),
    getCurrentStep: builder.query<EnrollmentStepConfig, any>({
      query: () => ({
        url: '/steps/currentSteps'
      })
    }),
    getWorkflowStep: builder.query<EnrollmentStepConfig, {
      stepId: string;
      nav?: string
    }>({
      query: ({ stepId, nav }) => ({
        url: `/steps/${stepId}`,
        method: 'GET',
        params: { nav }
      })
      // providesTags: (result, error, { workflowId, stepId }) => [{ types: 'WorkflowStep', id: `${workflowId}-${stepId}` }],
    }),
    updateWorkflowStep: builder.mutation<EntityModelAcceptedResponse, {
      stepId: string;
      body: any
    }>({
      query: ({ stepId, body }) => ({
        url: `/steps/${stepId}`,
        method: 'PUT',
        ...(body) ? { body } : {}
      })
      // invalidatesTags: (result, error, { workflowId, stepId }) => [{ types: 'WorkflowStep', id: `${workflowId}-${stepId}` }],
    }),
    loginToWorkflow: builder.mutation<EntityModelEnrollmentToken, LoginPayload>({
      query: ({ accessToken, enrollmentId }) => ({
        url: `/login`,
        method: 'POST',
        body: {
          accessToken,
          ...(enrollmentId) ? { enrollmentId } : {},
        }
      })
      // invalidatesTags: (result, error, { workflowId }) => [{ types: 'Workflow', id: workflowId }],
    }),
    getEnrollment: builder.query<any, { workflowId: string; enrollmentId: string }>({
      query: ({ workflowId, enrollmentId }) => ({
        url: `/enrollments/${enrollmentId}`,
        method: 'GET',
      })
      // providesTags: (result, error, { workflowId, enrollmentId }) => [{ types: 'Enrollment', id: `${workflowId}-${enrollmentId}` }],
    }),
    getFileUrl: builder.query<FileDownloadResponse, {
      fileId: string
    }>({
      query: ({ fileId }) => ({
        url: `/files/${fileId}`,
        method: 'GET'
      })
      // providesTags: (result, error, { workflowId, stepId }) => [{ types: 'WorkflowStep', id: `${workflowId}-${stepId}` }],
    })
  })
});

export const {
  useGetWorkflowConfigQuery,
  useLazyGetWorkflowConfigQuery,
  useLazyGetWorkflowStepQuery,
  useLazyGetCurrentStepQuery,
  useUpdateWorkflowStepMutation,
  useLoginToWorkflowMutation,
  useLazyGetFileUrlQuery
} = deviceEnrollmentApi;

