import React, {  } from 'react'
import { ActionProps } from '../types'
import { Form, Input, Space } from 'antd'
import { MacRegActionConfig } from '../types/MacRegActionConfig'
import { defineMessage, useIntl } from 'react-intl'


const MacRegAction: React.FC<ActionProps<MacRegActionConfig>> = ({ config, form, setDisableContinue }) => {

  const { $t } = useIntl()
  const macRegexString = {
    name: 'macAddress',
    regex: new RegExp(/^(?:[0-9A-Fa-f]{2}([-:]))(?:[0-9A-Fa-f]{2}\1){4}[0-9A-Fa-f]{2}$|^([0-9A-Fa-f]{4}\.){2}[0-9A-Fa-f]{4}$/),
    required: true,
    errorMessage: defineMessage({ defaultMessage: 'Please enter a valid MAC address' }),
  }
  return (
    <>
    <Space direction='vertical'  style={{ display: 'flex' }}>
      <Form.Item
        label={$t({ defaultMessage: 'Enter the MAC address of your device here:'})}
        labelCol={{span:24}}
        wrapperCol={{span:22}}
        name={"macAddress"}
        rules={[{
          pattern: macRegexString.regex,
          message: $t(macRegexString.errorMessage),
          required: macRegexString.required
        }]}
      >

        <Input />
      </Form.Item>
      </Space>
    </>
  )
}

export default MacRegAction
