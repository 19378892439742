import React                   from 'react'
import {ActionProps} from '../types'
import {Form, Input, Space, Typography}           from 'antd'
import {FieldVariable, UserInfoConfig} from '../types/UserInfoConfig'
import { defineMessage, useIntl } from 'react-intl'
import PhoneInput from '../components/PhoneInput'
import { phoneRegExp } from '../components/PhoneValidation'

type Variable = {
  name: string,
  regex: string,
  required?: boolean,
  maxLength: number,
  errorMessage?: { defaultMessage: string }
}

const variableTypes: Record<string, Variable> = {
  FIRST_NAME: {
    name: 'firstName',
    regex: '^[a-zA-Z0-9\- ]+$',
    required: true,
    maxLength: 100
  },
  LAST_NAME: {
    name: 'lastName',
    regex: '^[a-zA-Z0-9\- ]+$',
    required: true,
    maxLength: 100
  },
  USER_NAME: {
    name: 'userName',
    regex: '^.+$',
    required: true,
    maxLength: 100
  },
  EMAIL: {
    name: 'email',
    regex: '^[\\w\\.-]+@[a-zA-Z\\d\\.-]+\\.[a-zA-Z]{2,}$',
    required: true,
    errorMessage: defineMessage({defaultMessage: 'Please enter a valid email address'}),
    maxLength: 100
  },
  PHONE: {
    name: 'phoneNumber',
    regex: '^[0-9]+$',
    required: true,
    maxLength: 16,
    errorMessage: defineMessage({defaultMessage: 'Please enter a valid phone number'}),
  }
}

export function DataPromptAction(props:ActionProps<UserInfoConfig>) {

  const { $t } = useIntl()

  const { Text } = Typography

  const form = Form.useFormInstance()
  const {config} = props

  const trailingNorLeadingSpaces = function (value: string) {
    if (value && (value.endsWith(' ') || value.startsWith(' '))) {
      return Promise.reject($t({defaultMessage: 'No leading or trailing spaces allowed'}))
    }
    return Promise.resolve()
  }

  const getFormItem = function(formField:FieldVariable) {
    const inputFieldMatch = formField.type.match(/INPUT_FIELD_([0-9]+)/)

    if(formField.type === 'PHONE') {
      return (
        <Form.Item
          label={formField.label}
          name={variableTypes[formField.type].name}
          key={variableTypes[formField.type].name}
          required={true}
          rules={[
            {validator: (_, value) => phoneRegExp(value, $t(defineMessage({
              defaultMessage: 'Please enter a valid phone number'
            }))) }
          ]}
          children={
            <PhoneInput
              name={variableTypes[formField.type].name}
              onTop={false}
              callback={value => {
                form.setFieldValue(variableTypes[formField.type].name, value)
                form.validateFields([variableTypes[formField.type].name])
              }}
            />}
          validateFirst
        />)
    } else if(variableTypes[formField.type]) {
      return (
      <Form.Item
        label={formField.label}
        name={variableTypes[formField.type].name}
        key={variableTypes[formField.type].name}
        rules={[{
          pattern: new RegExp(variableTypes[formField.type].regex),
          message: variableTypes[formField.type].errorMessage ? $t(variableTypes[formField.type].errorMessage!) : $t({defaultMessage: 'Invalid Input'}),
          required: variableTypes[formField.type].required},
          {validator: (_, value) => trailingNorLeadingSpaces(value)},
          {min: 2},
          {max: variableTypes[formField.type].maxLength ? variableTypes[formField.type].maxLength : 250}]}
        >
        <Input maxLength={variableTypes[formField.type].maxLength}
          type={formField.type === 'EMAIL' ? 'email' : 'text'}></Input>
      </Form.Item>)
    } else if(inputFieldMatch && inputFieldMatch.length === 2) {
      return (
        <Form.Item
          label={formField.label}
          name={`inputField${inputFieldMatch[1]}`}
          key={`inputField${inputFieldMatch[1]}`}
          rules={[{
            pattern: new RegExp('.*'),
            message: $t({defaultMessage: 'Invalid Input'}),
            required: true},
            {validator: (_, value) => trailingNorLeadingSpaces(value)},
            {min: 2},
            {max: 1000}]}
          >
          <Input maxLength={1000}></Input>
        </Form.Item>)
    }
  }
  
  return (
    <>
    { config?.displayMessageHtml &&
      <Space direction='vertical' align='center' style={{marginBottom: '16px'}}>
        <Text>{config?.messageHtml}</Text>
      </Space>
    }

      {config?.variables?.map((formField:FieldVariable) =>
        formField && getFormItem(formField)
      )}
    </>
  )
}

export default DataPromptAction
